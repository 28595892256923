
import { defineComponent, onMounted } from "vue";
import Widget1 from "@/views/pages/dashboard-component/Widget1.vue";
import Widget2 from "@/views/pages/dashboard-component/Widget2.vue";
import Widget3 from "@/views/pages/dashboard-component/Widget3.vue";
import Widget4 from "@/views/pages/dashboard-component/Widget4.vue";
import Widget5 from "@/views/pages/dashboard-component/Widget5.vue";
// import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
// import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
// import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
// import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
// import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
// import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
// import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
// import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
// import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
// import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    Widget1,
    Widget2,
    Widget3,
    Widget4,
    Widget5
    // TableWidget9,
    // TableWidget5,
    // ListWidget1,
    // ListWidget2,
    // ListWidget3,
    // ListWidget5,
    // ListWidget6,
    // MixedWidget2,
    // MixedWidget5,
    // MixedWidget7,
    // MixedWidget10,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
