import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "row gy-5 gx-xl-8" }
const _hoisted_4 = { class: "col-xxl-6" }
const _hoisted_5 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Widget5 = _resolveComponent("Widget5")!
  const _component_Widget3 = _resolveComponent("Widget3")!
  const _component_Widget4 = _resolveComponent("Widget4")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Widget5)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Widget3, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Widget4, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
      ])
    ])
  ], 64))
}