
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import * as JWT from '@/core/services/JwtService';

export default defineComponent({
  name: "widget-5",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const responsables = ref<any>([]);
    const declarations = ref([]);
    let loading = ref(false);
    let pageSize = ref(5);
    let page = ref(1);
    let responsablesTotal = ref(0);
    let declarationsTotal = ref(0);


    const getResponsables= () => {
      loading.value = true;

      ApiService.query('/api/responsableTechnique/permissionnaires/' + JWT.getAuthUser()?.id, {
        params: {
          size: pageSize.value,
          page: page.value - 1,
          sort: 'createdDate,asc'
        }
      }).then((res: any) => {
        responsables.value = res.data.ResponsableTechnique;
        responsablesTotal.value = res.data.totalItems;
      }).catch(error => { 
        loading.value = false;
      });
    }

    const getDeclarations= () => {
      loading.value = true;

      ApiService.query('/api/declarations/permissionnaire/' + JWT.getAuthUser()?.id, {
        params: {
          size: pageSize.value,
          sort: 'createdDate,desc'
        }
      }).then((res: any) => {
            declarations.value = res.data.content;
            declarationsTotal.value = res.data.totalElements
            loading.value = false;
      }).catch(error => { 
        loading.value = false;
      });
    }


    onMounted(() => {
      getDeclarations();
      getResponsables();
      
    });

    const checked = ref(false);


    return {
      checked,
      responsables,
      declarationsTotal,
      responsablesTotal
    };
  },
});
