
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import * as JWT from '@/core/services/JwtService';

export default defineComponent({
  name: "widget-3",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const declarations = ref([]);
    let pageSize = ref(5);
    let loading = ref(false);
    const totalItems = ref(0);

    const getDeclarations= () => {
      loading.value = true;

      ApiService.query('/api/declarations/permissionnaire/' + JWT.getAuthUser()?.id, {
        params: {
          size: pageSize.value,
          sort: 'createdDate,desc'
        }
      }).then((res: any) => {
            declarations.value = res.data.content;
            totalItems.value = res.data.totalElements;
            loading.value = false;
      }).catch(error => { 
        loading.value = false;
      });
    }

    onMounted(() => {
      getDeclarations();
    });

    const checked = ref(false);

    const list = [
      {
        image: "media/avatars/150-11.jpg",
        name: "Ana Simmons",
        skills: "HTML, JS, ReactJS",
        companyName: "Intertico",
        companySkills: "Web, UI/UX Design",
        value: "50",
        color: "primary",
      },
      {
        image: "media/avatars/150-3.jpg",
        name: "Jessie Clarcson",
        skills: "C#, ASP.NET, MS SQL",
        companyName: "Agoda",
        companySkills: "Houses & Hotels",
        value: "70",
        color: "danger",
      },
      {
        image: "media/avatars/150-4.jpg",
        name: "Lebron Wayde",
        skills: "PHP, Laravel, VueJS",
        companyName: "RoadGee",
        companySkills: "Transportation",
        value: "60",
        color: "success",
      },
      {
        image: "media/avatars/150-5.jpg",
        name: "Natali Goodwin",
        skills: "Python, PostgreSQL, ReactJS",
        companyName: "The Hill",
        companySkills: "Insurance",
        value: "50",
        color: "warning",
      },
      {
        image: "media/avatars/150-6.jpg",
        name: "Kevin Leonard",
        skills: "HTML, JS, ReactJS",
        companyName: "RoadGee",
        companySkills: "Art Director",
        value: "90",
        color: "info",
      },
    ];

    return {
      list,
      checked,
      getDeclarations,
      declarations,
      totalItems,
      pageSize,
      loading
    };
  },
});
